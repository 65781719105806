const url = process.env.REACT_APP_BASE_URL;
const url2 = process.env.REACT_APP_BASE_URL_BYBIT;
const url3 = process.env.REACT_APP_BASE_URL_BINANCE;
const predictionURL = process.env.REACT_APP_BASE_PREDICTION_URL;
export const socketURL = process.env.REACT_APP_BASE_SOCKET_URL;
export const loginURL = process.env.REACT_APP_LOGIN_URL;

// const url = 'https://node.olympustrade.ai/api/v1';
// const url2 = 'https://main-ai.olympustrade.ai';
// const url3 = 'https://binance-ai.olympustrade.ai';
// const predictionURL = 'https://predictionnode.olympustrade.ai/api/v1';
// export const socketURL = 'wss://node.olympustrade.ai/';
// export const loginURL = process.env.REACT_APP_LOGIN_URL;

export const socketNotification = "";
export const baseUrl = "";
const admin = `${url}/admin`;
const predictionAdmin = `${predictionURL}/admin`;

const ApiConfig = {
  userLogin: `${admin}/login`,
  forgotPassword: `${admin}/forgotPasswordSendOTP`,
  verifyOTP: `${admin}/verifyForgotPasswordOTP`,
  resetPassword: `${admin}/resetPassword`,
  changepassword: `${admin}/changePassword`,
  loginOTPVerify: `${admin}/loginOTPVerify`,
  changePasswordSendOTP: `${admin}/changePasswordSendOTP`,
  changePasswordOTPVerify: `${admin}/changePasswordOTPVerify`,
  listUser: `${admin}/listUser`,
  createSignal: `${url2}/bybit-create-signal`,
  createSignalBinance: `${url3}/binance-create-signal`,
  userDetails: `${admin}/viewUserProfile`,
  blockUnblock: `${admin}/blockUnblockUser`,
  deleteUser: `${admin}/deleteUser`,
  getCountryCount: `${admin}/getCountryCount`,
  allBanner: `${url}/banner/get-all-banners`,
  addBanner: `${url}/banner/addBanner`,
  editBanner: `${url}/banner/editBanner`,
  deleteBanner: `${url}/banner/deleteBanner`,
  allBannerWeb: `${url}/banner/get-all-banners-of-web`,
  addBannerWeb: `${url}/banner/addbannerOfWeb`,
  editBannerWeb: `${url}/banner/editBannerofWeb`,
  deleteBannerWeb: `${url}/banner/deleteBannerofWeb`,
  editDiscount: `${admin}/updatePlanData`,
  announcementContentList: `${url}/announcement/announcementContentList`,
  viewannouncementContent: `${url}/announcement/viewannouncementContent`,
  editannouncementContent: `${url}/announcement/editannouncementContent`,
  deleteannouncement: `${url}/announcement/deleteannouncement`,
  addannouncementContent: `${url}/announcement/addannouncementContent`,
  discountManagementData: `${url}/buy/getTradingBotDetails`,
  dashboardCount: `${url}/admin/adminDashbordcount`,
  assetsData: `${admin}/assetsTransactionList`,
  referralData: `${admin}/referralList`,
  assetsTransactionList: `${url}/admin/assetsTransactionList`,
  approveWithdrawReq: `${url}/admin/approveWithdrawReq`,
  viewUserSubscriptionDetails: `${url}/admin/viewUserSubscriptionDetails`,
  viewUserLoginActivity: `${url}/admin/viewUserLoginActivity`,
  viewUserWalletTransactions: `${url}/admin/viewUserWalletTransactions`,
  viewUserConnectedExchanges: `${url}/admin/viewUserConnectedExchanges`,
  getListStatic: `${url}/static-content/staticContentList`,
  getSingleStaticContent: `${url}/static-content/getSingleStaticContent`,
  editStaticContent: `${url}/static-content/editStaticContent`,
  contactUsList: `${url}/admin/contact-us-list`,
  viewContactUs: `${url}/admin/viewContactUs`,
  newsLettersubscriberList: `${url}/admin/newsLettersubscriberList`,
  sendNotificationToAllUser: `${url}/admin/sendNotificationToAllUser`,
  adminDashbordcount: `${url}/admin/adminDashbordcount`,
  viewUserReferralDetails: `${url}/admin/viewUserReferralDetails`,
  articleManagement: `${url}/admin/listCategory`,
  addArticleCategory: `${url}/admin/createCategory`,
  editCategory: `${url}/admin/editCategory`,
  getArticleCategoryById: `${url}/admin/listArticleByCategoryId`,
  addArticle: `${url}/admin/addArticle`,
  blockUnblockArticle: `${url}/admin/blockUnblockArticle`,
  deleteCategory: `${url}/admin/deleteCategory`,
  viewArticle: `${url}/admin/viewArticle`,
  updateArticle: `${url}/admin/updateArticle`,
  getBatchlist: `${url}/admin/asste-transaction-withdraw-batch-list`,
  getBatchById: `${url}/admin/asste-transaction-withdraw-list`,
  listAdvertisment: `${url}/admin/listAdvertisment`,
  blockUnblockAdvertisment: `${url}/admin/blockUnblockAdvertisment`,
  addAdvertisment: `${url}/admin/addAdvertisment`,
  editAdvertisment: `${url}/admin/editAdvertisment`,
  tradeHistory: `${url}/indicator/master-get-trade-history`,
  tradeDetailsHistory: `${url}/indicator/get-user-open-trades`,
  activeTradingPairs: `${url}/indicator/master-activeTradingPairs`,
  tradeCoinDetails: `${url}/indicator/get-coin-trade-details?limit=${10}&page=1`,
  // prediction managment
  listPrediction: `${predictionURL}/admin/listPrediction`,
  viewPredictionPool: `${predictionURL}/admin/viewPredictionPool`,
  viewPredictionPoolParticipants: `${predictionURL}/admin/viewPredictionPoolParticipants`,
  // support management
  feedbackList: `${url}/contactUs/feedbackList`,
  viewFeedback: `${url}/contactUs/viewFeedback`,
  respondFeedback: `${url}/contactUs/respondFeedback`,
  // strategy management
  listAIStrategy: `${url}/admin/listAIStrategy`,
  getSingleAIStrategy: `${url}/admin/getSingleAIStrategy`,
  bulkNotiifcation: `${url}/admin/sendNotificationToAllUser`,
  changeAiSyncStatus: `${url}/indicator/update-ai-handler`,
  getAiSyncStatus: `${url}/indicator/get-ai-handler`,
  listUserTrade: `${url}/indicator/master-userTrade-list`,
  createTrade: `${url}/indicator/create-order`,
  getPlanForUsers: `${url}/buy/get-plan-for-users`,
  depositManyInternal: `${url}/admin/deposite-many-internalwallet`,
  adminRemoveTrade: `${url}/indicator/admin-remove-trades`,
  getAiUsersList: `${url}/indicator/get-ai-users-list`,
  updateCoinBlockList: `${url}/indicator/update-coin-block-list`,
  userOrderList: `${url}/indicator/master-get-users-order-list`,
  blockAiUser: `${url}/indicator/update-ai-user`,
  profitCount: `${url}/indicator/strategy-profit`,

  // fx

  getFxAccount: `${url}/fx/get-account-list`,
  updateFxAccount: `${url}/fx/update-account`,

  fxTradeList: `${url}/fx/admin-open-trades`,
  fxTradeSingle: `${url}/fx/admin-open-trade-user-list`,
  fxTransactionList: `${url}/fx/admin-trade-history`,
  fxTransactionSingle: `${url}/fx/admin-trade-history-user-list`,
  fxUserList: `${url}/fx/get-account-list`,
  fxUserListSingle: `${url}/fx/admin-user-open-trades`,
};

export default ApiConfig;
