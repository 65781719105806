import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
import HomeLayout from "src/layouts/HomeLayout";
import LoginLayout from "src/layouts/LoginLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LogIn")),
  },

  {
    exact: true,
    path: "/dashboard",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard")),
  },
  {
    exact: true,
    path: "/trade-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/TradeManagement/index")),
  },
  {
    exact: true,
    path: "/referral-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ReferralManagement/index")),
  },
  {
    exact: true,
    path: "/view-referral",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ReferralManagement/view")),
  },
  {
    exact: true,
    path: "/announcement",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/LastestAnnouncement/index")),
  },
  {
    exact: true,
    path: "/edit-announcement",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/LastestAnnouncement/Edit.js")
    ),
  },
  {
    exact: true,
    path: "/view-announcement-Details",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/LastestAnnouncement/Announcement")
    ),
  },
  {
    exact: true,
    path: "/add-announcement",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/LastestAnnouncement/addAds")),
  },
  {
    exact: true,
    path: "/assets-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/AssetsManagement/index")),
  },
  {
    exact: true,
    path: "/view-assets",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/AssetsManagement/ViewAssets")
    ),
  },
  {
    exact: true,
    path: "/view-withdraw",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/AssetsManagement/viewWithdraw.js")
    ),
  },
  {
    exact: true,
    path: "/view-transfer",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/AssetsManagement/viewTransfer.js")
    ),
  },

  {
    exact: true,
    path: "/verify-otp",

    layout: LoginLayout,
    component: lazy(() =>
      import("src/views/auth/forget-password-link/OtpVerify")
    ),
  },
  {
    exact: true,
    path: "/login-otp-verification",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LogIn/loginOtpVerify")),
  },
  {
    exact: true,
    path: "/reset-password",

    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/reset-password/index")),
  },

  {
    exact: true,
    path: "/changePassword",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Change Password/Index")),
  },
  // {
  //   exact: true,
  //   path: "/2fa-authentication",
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/TwofactorAuth/index")),
  // },
  {
    exact: true,
    path: "/forget-password",

    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/forget-password/index")),
  },
  {
    exact: true,
    path: "/user-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/userManagement")),
  },
  {
    exact: true,
    path: "/fx-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/fxManagement")),
  },
  {
    exact: true,
    path: "/fx-trade-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/FxTradeManagement/index")),
  },
  {
    exact: true,
    path: "/fx-activated-coin-details",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/FxTradeManagement/activatedCoinsDetails")
    ),
  },
  {
    exact: true,
    path: "/fx-recent-trade-Details",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/FxTradeManagement/recentDetails")
    ),
  },
  {
    exact: true,
    path: "/fx-activated-coin-user-details",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/FxTradeManagement/activatedcoinUserDetails")
    ),
  },
  {
    exact: true,
    path: "/ticket-management",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ticketManagement/index.js")),
  },
  {
    exact: true,
    path: "/ticket-details",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/ticketManagement/ticketDetails.js")
    ),
  },
  {
    exact: true,
    path: "/ticket-reply",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/ticketManagement/ticketReply.js")
    ),
  },

  {
    exact: true,
    path: "/exchange-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/ExchangeManagement/index.js")
    ),
  },
  {
    exact: true,
    path: "/view-user-details",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/userManagement/userDetails")),
  },
  {
    exact: true,
    path: "/view-trade-user",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/TradeManagement/tradeView")),
  },
  {
    exact: true,
    path: "/activated-coin-details",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/TradeManagement/activatedCoinsDetails")
    ),
  },
  {
    exact: true,
    path: "/activated-coin-user-details",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/TradeManagement/activatedcoinUserDetails")
    ),
  },
  {
    exact: true,
    path: "/recent-trade-Details",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/TradeManagement/recentDetails")
    ),
  },
  {
    exact: true,
    path: "/recent-trade-Details-View",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/TradeManagement/recentDetailsView")
    ),
  },

  {
    exact: true,
    path: "/fuel-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/FuleManagement/FuleManagement")
    ),
  },
  {
    exact: true,
    path: "/edit-plan",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/FuleManagement/EditPlan")),
  },
  {
    exact: true,
    path: "/add-plan",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/FuleManagement/AddPlan")),
  },
  {
    exact: true,
    path: "/view-fuel-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/FuleManagement/ViewFuelManagement")
    ),
  },
  {
    exact: true,
    path: "/discount-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/DiscountManagement/DiscountManagement")
    ),
  },
  {
    exact: true,
    path: "/add-discount",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/DiscountManagement/AddDiscount")
    ),
  },
  {
    exact: true,
    path: "/edit-discount",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/DiscountManagement/EditDiscount")
    ),
  },

  {
    exact: true,
    path: "/banner-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/BManagement/BannerManagement")
    ),
  },
  {
    exact: true,
    path: "/view-banner",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/BManagement/ViewBanner")),
  },
  {
    exact: true,
    path: "/add-banner",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/BManagement/AddBanner")),
  },
  {
    exact: true,
    path: "/edit-banner",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/BManagement/EditBanner")),
  },
  {
    exact: true,
    path: "/withdrawal-batch",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/widthdrawlRequest")),
  },
  {
    exact: true,
    path: "/withdrawal-request",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/widthdrawlRequest/withdrawlRequest")
    ),
  },
  {
    exact: true,
    path: "/content-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/StaticContent/contentManagement")
    ),
  },
  {
    exact: true,
    path: "/view-content",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/StaticContent/viewContent")),
  },
  {
    exact: true,
    path: "/edit-content",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/StaticContent/editContent")),
  },
  {
    exact: true,
    path: "/advertisement-banner-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/AdvertisementBanner/BannerManagement")
    ),
  },
  {
    exact: true,
    path: "/add-advertisement-banner",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/AdvertisementBanner/AddBanner")
    ),
  },
  {
    exact: true,
    path: "/edit-advertisement-banner",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/AdvertisementBanner/EditBanner")
    ),
  },
  {
    exact: true,
    path: "/subscriber-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/subscribe/subscribeManagement")
    ),
  },
  {
    exact: true,
    path: "/contact-us-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/contact/contactManagement")),
  },
  {
    exact: true,
    path: "/view-contact-details",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/contact/viewContact")),
  },
  {
    exact: true,
    path: "/prediction-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/predictionManagement")),
  },
  {
    exact: true,
    path: "/view-prediction-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/predictionManagement/viewPredictioPool")
    ),
  },
  {
    exact: true,
    path: "/article-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/ArticleManagement/contentManagement")
    ),
  },
  {
    exact: true,
    path: "/view-category",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/ArticleManagement/viewCategory")
    ),
  },
  {
    exact: true,
    path: "/add-article",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/ArticleManagement/addArticle")
    ),
  },
  {
    exact: true,
    path: "/view-article",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/ArticleManagement/viewArticle")
    ),
  },
  {
    exact: true,
    path: "/edit-article",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/ArticleManagement/editArticle")
    ),
  },
  {
    exact: true,
    path: "/support-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/supportManagement")),
  },
  {
    exact: true,
    path: "/view-individual-problems",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/supportManagement/viewMessage")
    ),
  },
  {
    exact: true,
    path: "/send-message",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/supportManagement/respond")),
  },
  {
    exact: true,
    path: "/strategy-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/StrategyManagement")),
  },
  {
    exact: true,
    path: "/view-strategy-details",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/StrategyManagement/individualStrategy")
    ),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
